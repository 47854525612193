export const formatValues = (values, withEmptyOption, name) => {
  const options = [];
  values.forEach((e) => {
    options.push({
      value: e.id,
      label: e.name ? e.name.charAt(0).toUpperCase() + e.name.toLowerCase().slice(1) : e.name,
      ...e,
    });
  });
  if (withEmptyOption) {
    const emptyOption = { label: name, value: 0 };
    return [emptyOption, ...options];
  }
  return options;
};
