import React from 'react';
import PropTypes from 'prop-types';

import SlideArrowIcon from 'components/icons/SlideArrow';
import { Button } from './Arrow.styles';

const Arrow = ({ direction, onClick }) => (
  <Button direction={direction} onClick={onClick}>
    <SlideArrowIcon height="14px" />
  </Button>
);

Arrow.propTypes = {
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
  onClick: PropTypes.func,
};

Arrow.defaultProps = {
  onClick: () => {},
};

export default Arrow;
