import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  background-color: ${(props) => props.theme.backgroundColor};
  box-shadow: 1px 1px 3px 1px #e9e9e9;
  position: relative;
  padding: 0 24px 20px 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 22px;
  align-items: center;
  width: auto;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
  }
`;

const Margin = styled.div`
  display: flex;
  justify-content: flex-start;
  width: calc(25% - 20px);
  height: 100%;

  &:first-of-type {
    padding-top: 24px;
    padding-right: 12px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-right: 0;
  }
  ${(props) => props.rightMargin && css`
    margin-right: 0;
  `}
  ${(props) => props.narrow && css`
    width: 50px;
  `}
`;

const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;

  & > button {
    margin-left: 0;
  }
`;

const TextLinkWrapper = styled.div`
  display: inline-block;
  padding: 0 10px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.2px;
`;

const FullArrowContainer = styled.div`
  display: flex;
  padding-top: 10px;

  svg {
    margin-top: 9px;
    margin-left: -4px;
    width: 8px;
    height: auto;
    transform: rotateZ(90deg);
  }
`;

export {
  Wrapper, Row, Margin, TextLinkWrapper, SearchButtonWrapper, FullArrowContainer,
};
