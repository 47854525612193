import React from 'react';
import { FormattedMessage } from 'react-intl';
import SocialLinks from 'components/SocialLinks';
import { socialLinks } from 'components/layouts/PageLayout/PageLayout.data';
import messages from './FooterCopyright.messages';

import {
  CopyrightWrapper,
  SocialLinksWrapper,
  Container,
  Wrapper,
  InformationLinkWrapper,
  RedirectsWrapper,
} from './FooterCopyright.styles';

const FooterCopyright = () => (
  <Wrapper>
    <Container>
      <CopyrightWrapper>
        <FormattedMessage {...messages.copyright} />
      </CopyrightWrapper>
      <RedirectsWrapper>
        <InformationLinkWrapper route="legalInformation">
          <FormattedMessage {...messages.information} />
        </InformationLinkWrapper>
        <SocialLinksWrapper>
          <SocialLinks items={socialLinks} darkColor smallIcons />
        </SocialLinksWrapper>
      </RedirectsWrapper>
    </Container>
  </Wrapper>
);

export default FooterCopyright;
