import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  height: 100%;

  > svg {
    height: 100%;
    padding: 0;
    box-sizing: border-box;
  }
`;

export { Wrapper };
