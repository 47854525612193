import { defineMessages } from 'react-intl';

export default defineMessages({
  phone: {
    id: 'ContactUsEntry.phone',
    defaultMessage: 'tel.',
  },
  email: {
    id: 'ContactUsEntry.email',
    defaultMessage: 'e-mail:',
  },
  open: {
    id: 'ContactUsEntry.open',
    defaultMessage: 'Godziny otwarcia:',
  },
  openInfo: {
    id: 'ContactUsEntry.openInfo',
    defaultMessage: '(inne terminy po wcześniejszym umówieniu spotkania)',
  },
  covidInfo: {
    id: 'ContactUsEntry.covidInfo',
    defaultMessage: 'Więcej informacji odnośnie funkcjonowania w związku z epidemią koronawirusa',
  },
});
