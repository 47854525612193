export const getImgDimensions = (imageUrl) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => {
    resolve({ width: img.width, height: img.height });
  };
  img.onerror = () => {
    reject(new Error('Failed to load image'));
  };
  img.src = imageUrl;
});
