import styled from 'styled-components';
import Link from 'components/ui/Link';

export const Button = styled(Link)`
  background-color: #E30613;
  text-transform: uppercase;
  letter-spacing: 1.1px;
  color: ${(props) => props.theme.white} !important;
  width: calc(100% - 80px);
  height: 49px;
  border-radius: 4px;
  margin: 28px auto 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding-left: 21px;
  padding-right: 21px;
  letter-spacing: 1.2px;

  &:hover {
    background-color: #CA000C;
  }


  svg {
    width: 21px;
  }
`;
