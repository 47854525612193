import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Magnifier from 'components/icons/Magnifier';
import messages from './SearchButton.messages';
import {
  SearchButton, Icon,
} from './SearchButton.styles';

const Button = ({ onClick, showText }) => (
  <SearchButton onClick={onClick}>
    <Icon>
      <Magnifier />
    </Icon>
    {showText ? (
      <FormattedMessage {...messages.text} />
    ) : null}
  </SearchButton>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  showText: PropTypes.bool,
};

Button.defaultProps = {
  showText: true,
};

export default Button;
