import styled from 'styled-components';
import Link from 'components/ui/Link';

const Description = styled.div`
  display: none;
  color: ${(props) => props.theme.primary2Color};

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    display: block;
    padding: 0 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  display: inline-flex;
  background-color: ${(props) => props.theme.primary4Color};
  align-items: center;
  text-transform: uppercase;
  transition: transform ${(props) => props.theme.animationDuration};
  width: 100%;
  pointer-events: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: initial;
    margin: 10px 0;
    transform: translateX(calc(100% - 60px));

    &:hover {
      transform: translateX(0%);
    }
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  background-color: ${(props) => props.theme.primary4Color};
  align-items: center;
  text-transform: uppercase;
  transition: transform ${(props) => props.theme.animationDuration};
  width: 100%;
  pointer-events: auto;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    width: initial;
    margin: 10px 0;
    transform: translateX(calc(100% - 60px));

    &:hover {
      transform: translateX(0%);
    }
  }
`;

export {
  StyledLink, Description, Wrapper, StyledContainer,
};
