import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.backgroundColor};
  flex-direction: column-reverse;
  overflow: hidden;
`;

const Column = styled.div`
  width: 100%;
  height: 495px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
`;

export {
  RightContainer,
  Column,
  Wrapper,
};
