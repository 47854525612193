import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundColor};
  ${(props) => props.alignCenter && css`
    text-align: center;
  `}
  ${(props) => props.transparentBackground && css`
    background-color: transparent;
  `}
`;

const ItemsWrapper = styled.div`
  padding: 35px 0 100px 0;
  display: flex;
  align-items: flex-start;
  ${(props) => props.rowLayout && css`
    flex-direction: column;
  `}

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    padding-left: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
 
  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 13px 0 20px 0;
  }
`;

const ItemWrapper = styled.div`
  flex-basis: 33.3%;
  padding: 15px 5px 0 0;
`;

const TitleBold = styled.span`
  font-weight: 900;
`;

const Title = styled.span`
  font-weight: 300;
`;

const TopHeader = styled.div`
  font-weight: 300;
  padding: 0 0 15px;
`;

export {
  TitleBold,
  Title,
  TopHeader,
  ItemWrapper,
  ItemsWrapper,
  Wrapper,
};
