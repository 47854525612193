import React from 'react';
import Proptypes from 'prop-types';
import {
  ContactWrapper, Avatar, Name, Position, InfoContainer, Row, Label, Link,
} from './Contact.styles';
import WhatsAppIcon from '../../../../../icons/WhatsApp';


const Contact = ({
  name, image, position, phone, alt,
}) => (
  <ContactWrapper>
    <Avatar src={image} alt={alt} />
    <InfoContainer>
      <Name>
        {name}
      </Name>
      <Position>
        {position}
      </Position>
      <Row>
        <Label><WhatsAppIcon /></Label>
        <Link href={`tel:${phone}`}>{phone}</Link>
      </Row>
    </InfoContainer>
  </ContactWrapper>
);

Contact.propTypes = {
  image: Proptypes.string.isRequired,
  name: Proptypes.string.isRequired,
  phone: Proptypes.string.isRequired,
  position: Proptypes.string.isRequired,
  alt: Proptypes.string,
};

Contact.defaultProps = {
  alt: '',
};


export default Contact;
