import { defineMessages } from 'react-intl';

export default defineMessages({
  search: {
    id: 'ContactIcons.search',
    defaultMessage: 'Wyszukiwarka',
  },
  mail: {
    id: 'ContactIcons.mail',
    defaultMessage: 'Napisz do nas',
  },
  phone: {
    id: 'ContactIcons.phone',
    defaultMessage: 'Zadzwoń do nas: {phone}',
  },
});
