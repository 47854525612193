import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';
import config from 'config';

import Caret from 'components/icons/Caret';
import PhoneEmpty from 'components/icons/PhoneEmpty';
import Clock from 'components/icons/Clock';
import messages from './ContactUsEntry.messages';
import {
  Address,
  Description,
  Column,
  CovidInfo,
  Row,
  EmptySpace,
  IconWrapper,
  Label,
  Link,
  Wrapper,
  StyledSpan,
  Email,
  OpeningHours,
} from './ContactUsEntry.styles';

const ContactUsEntry = ({
  address,
  open,
  phone,
  showIcons,
  theme,
  description,
  email,
  isCovidInfoHidden,
  route,
  otherDatesHidden,
}) => (
  <Wrapper>
    <Column>
      <Caret />
    </Column>
    <Column>
      <Address href={route} target="_blank" className="yl-link">
        {address}
      </Address>
      <Description>{description}</Description>
      <Row>
        {!showIcons ? null : (
          <IconWrapper>
            <PhoneEmpty fill={theme.primary1Color} />
          </IconWrapper>
        )}
        <Label>
          <FormattedMessage {...messages.phone} />
        </Label>
        <Link href={`tel:${phone}`}>
          <StyledSpan>{phone}</StyledSpan>
        </Link>
      </Row>
      {!email ? null : (
        <Row>
          <Label>
            <FormattedMessage {...messages.email} />
          </Label>
          <Link href={`mailto:${email}`}>
            <Email>{email}</Email>
          </Link>
        </Row>
      )}
      {open ? (
        <React.Fragment>
          <Row>
            {!showIcons ? null : (
              <IconWrapper>
                <Clock fill={theme.primary1Color} />
              </IconWrapper>
            )}
            <Label>
              <FormattedMessage {...messages.open} />
            </Label>
            <OpeningHours> {open}</OpeningHours>
          </Row>
          <Row>
            {!showIcons ? null : <EmptySpace />}
            {!otherDatesHidden && (
              <StyledSpan>
                <FormattedMessage {...messages.openInfo} />
              </StyledSpan>
            )}
          </Row>
          {!isCovidInfoHidden && (
            <Row>
              <Link href={config.covidInfoUrl} target="_blank">
                <CovidInfo>
                  <FormattedMessage {...messages.covidInfo} />
                </CovidInfo>
              </Link>
            </Row>
          )}
        </React.Fragment>
      ) : null}
    </Column>
  </Wrapper>
);

ContactUsEntry.propTypes = {
  address: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  otherDatesHidden: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  email: PropTypes.string,
  isCovidInfoHidden: PropTypes.bool,
  open: PropTypes.string,
  route: PropTypes.string,
  showIcons: PropTypes.bool,
};

ContactUsEntry.defaultProps = {
  showIcons: false,
  isCovidInfoHidden: false,
  email: null,
  open: '',
  route: '',
};

export default withTheme(ContactUsEntry);
