import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/ui/Link';

import isPromotionActive from 'utils/isPromotionActive';
import { VisibilityContainer } from 'utils/styles';
import { FormattedMessage } from 'react-intl';
import { getImgDimensions } from 'utils/getImgDimensions';
import config from 'config';
import { LinkShape } from './Slide.shapes';

import messages from './Slide.messages';

import {
  Image,
  Wrapper,
  ImageWrapper,
  PromotionText,
  PromotionWrapper,
  InfoOverlayWrapper,
  InfoOverlayContainer,
  InfoOverlay,
  InfoOverlayTitle,
  InfoOverlayDescription,
  InfoOverlayButton,
} from './Slide.styles';

const handleImageLoaded = () => {
  window.dispatchEvent(new Event('resize'));
};

const Slide = ({
  imageUrl,
  link,
  title,
  description,
  promo,
  showModal,
  isHalfWidth,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(!imageUrl);
  const [imageDimensions, setImageDimensions] = useState(config.defaultBannerSize);

  const imageRef = useRef(null);

  const onLoad = () => {
    setIsImageLoaded(true);
  };

  let imageComponent = (
    <Image
      innerRef={imageRef}
      src={imageUrl}
      alt="slide"
      draggable={false}
      onLoad={handleImageLoaded}
      withPointer={!!showModal}
      onClick={() => { if (showModal) { showModal(imageUrl); } }}
      isHalfWidth={isHalfWidth}
    />
  );

  if (link && link.href) {
    imageComponent = (
      <Link
        href={link.href}
        route={link.route}
        params={link.params}
        target="_top"
        draggable={false}
      >
        {imageComponent}
      </Link>
    );
  }

  useEffect(() => {
    if (imageRef.current) {
      onLoad();
    }

    getImgDimensions(imageUrl)
      .then((dimensions) => setImageDimensions(`${dimensions.width} / ${dimensions.height}`));
  }, [imageUrl]);

  return (
    <Wrapper>
      <ImageWrapper isImageLoaded={isImageLoaded} imageDimensions={imageDimensions}>
        {imageComponent}
      </ImageWrapper>
      {
        promo && isPromotionActive(promo.entity.date) ? (
          <PromotionWrapper route="promotionsList" params={{ page: 1 }}>
            <PromotionText>
              {promo.entity.name}
            </PromotionText>
          </PromotionWrapper>
        ) : null
      }
      <VisibilityContainer lg>
        {
        title && (
          <InfoOverlayWrapper>
            <InfoOverlayContainer>
              <InfoOverlay>
                <InfoOverlayTitle>
                  {title}
                </InfoOverlayTitle>
                {
                  description && (
                    <InfoOverlayDescription>
                      {description}
                    </InfoOverlayDescription>
                  )
                }
                <Link
                  href={link.href}
                  route={link.route}
                  params={link.params}
                  target="_top"
                  draggable={false}
                >
                  <InfoOverlayButton>
                    <FormattedMessage {...messages.seeMore} />
                  </InfoOverlayButton>
                </Link>
              </InfoOverlay>
            </InfoOverlayContainer>
          </InfoOverlayWrapper>
        )
      }
      </VisibilityContainer>
    </Wrapper>
  );
};

Slide.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  isHalfWidth: PropTypes.bool,
  link: LinkShape,
  promo: PropTypes.shape({
    entity: PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  }),
  showModal: PropTypes.func,
  title: PropTypes.string,
};

Slide.defaultProps = {
  link: null,
  title: null,
  description: null,
  promo: null,
  showModal: undefined,
  isHalfWidth: false,
};

export default Slide;
