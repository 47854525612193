import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { isUndefined } from 'lodash';

import Range from 'rc-slider/lib/Range';
import { FormattedNumber } from 'react-intl';
import { Wrapper, MinLegend, MaxLegend } from './Slider.styles';

const Slider = ({
  minValue,
  maxValue,
  minSelected,
  maxSelected,
  handleChange,
  theme,
  isWithoutExtraPadding,
  formatDisplay,
}) => {
  const trackStyle = {
    marginLeft: '10px',
    backgroundColor: theme.primary3Color,
    height: '4px',
  };

  const railStyle = {
    backgroundColor: theme.secondary5Color,
    borderRadius: '0',
    height: '4px',
    borderWidth: '1px 1px 0 1px',
    borderStyle: 'solid',
    borderColor: 'black',
    marginTop: '2px',
    marginLeft: '5px',
  };

  const dotStyle = {
    outline: 'none',
    boxShadow: 'none',
  };

  const minFinalValue = isUndefined(minSelected) ? minValue : minSelected;
  const maxFinalValue = isUndefined(maxSelected) ? maxValue : maxSelected;

  return (
    <Wrapper isWithoutExtraPadding={isWithoutExtraPadding}>
      <MinLegend>
        <FormattedNumber value={minFinalValue} maximumFractionDigits={0} />
        &nbsp;{formatDisplay}
      </MinLegend>
      <Range
        min={minValue}
        max={maxValue}
        trackStyle={[trackStyle]}
        handleStyle={[dotStyle, dotStyle]}
        railStyle={railStyle}
        allowCross={false}
        value={[
          minFinalValue,
          maxFinalValue,
        ]}
        onChange={handleChange}
      />
      <MaxLegend>
        <FormattedNumber value={maxFinalValue} maximumFractionDigits={0} />
        &nbsp;{formatDisplay}
      </MaxLegend>
    </Wrapper>
  );
};

Slider.propTypes = {
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  formatDisplay: PropTypes.string,
  handleChange: PropTypes.func,
  isWithoutExtraPadding: PropTypes.bool,
  maxSelected: PropTypes.number,
  minSelected: PropTypes.number,
};

Slider.defaultProps = {
  handleChange: () => {},
  maxSelected: null,
  minSelected: null,
  isWithoutExtraPadding: false,
  formatDisplay: null,
};

export default withTheme(Slider);
