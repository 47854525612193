import styled from 'styled-components';

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: ${(props) => props.theme.secondary13Color};
  flex-basis: 50%;
  max-width: 268px;
  
  &:not(:first-child) {
    margin-left: 32px;
  }
    
  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
      margin-bottom: 30px;
      &:nth-child(2n+1) {
        margin-left: 0;
      }
  }
  
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 20px;
    
      &:not(:first-child) {
        margin-left: 0;
      }
      
      max-width: 500px;
      min-width: 320px;
      flex-direction: row;
  }
`;

export const Avatar = styled.img`
  width: 268px;
  height: 280px;
  border-radius: 2px;
  object-fit: cover;
  object-position: top;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      width: 80px;
      height: 80px;
  }
`;

export const Name = styled.h5`
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.primary3Color};
  margin: 2px 0 0 0;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin: 0;
  }
`;

export const Position = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 2px;
  color: ${(props) => props.theme.primary5Color};

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 2px;
    margin-bottom: 0;
  }
`;

export const InfoContainer = styled.div`
  flex-basis: auto;
  margin-top: 10px;
  
   @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 12px;
  }
`;

export const Label = styled.p`
  font-size: 11px;
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;

  svg {
    height: 14px;
    width: auto;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {

    svg {
      height: 14px;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.primary3Color};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  margin-top: 10px;

  label {
    font-size: 11px;
    font-weight: 500;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 10px;
  }
`;
