import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: ${({ mapHeight }) => (mapHeight ? `${mapHeight}px` : '100%')};

  .gm-style iframe + div {
    border: none !important;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 200px;
  }
`;

const StaticWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.mapUrl});
  background-position: center center;
  background-size: cover;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Wrapper, StaticWrapper, Empty };
