import {
  isString, isEmpty, replace, isNull, isArray, forEach, includes, find, get,
} from 'lodash';
import config from 'config';
import errorLogger from 'utils/errorLogger';

import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const menuLinks = [{
  translatable: true, id: 'offer', value: 'offer', route: '/offer', active: false,
}, {
  translatable: true, id: 'start', value: 'start', route: '/start', active: false,
}, {
  translatable: true, value: 'news', route: '/lista-aktualnosci', params: { page: 1 },
}, {
  translatable: true, value: 'blog', route: '/blog', params: { page: 1 },
}, {
  translatable: true, id: 'contact', value: 'contact', route: '/kontakt',
}];

export const populateMenu = (menuFromDB) => menuLinks.map((el) => {
  const subMenu = find(menuFromDB, (el2) => el2.id === el.id);
  if (subMenu === undefined) {
    return el;
  }

  if (el.id === 'offer') {
    subMenu.children = subMenu.children.map((el2) => {
      const item = {
        ...el2,
        items: [],
      };

      item.items.push({
        translatable: true,
        value: 'investmentDescription',
        route: 'investmentDescriptionDetails',
        params: { id: el2.slug },
        elementId: config.locationIds.details,
        scroll: true,
      });

      item.items.push({
        translatable: true,
        value: 'searchEngines',
        route: 'investmentDescriptionSearch',
        params: { id: el2.slug },
        elementId: config.locationIds.search,
        scroll: false,
      });

      item.items.push({
        translatable: true,
        value: 'gallery',
        route: 'investmentDescriptionGallery',
        params: { id: el2.slug },
        elementId: config.locationIds.gallery,
        scroll: false,
      });

      item.items.push({
        translatable: true,
        value: 'poi',
        route: 'investmentDescriptionLocalization',
        params: { id: el2.slug },
        elementId: config.locationIds.localization,
        scroll: false,
      });
      return item;
    });
  }

  return {
    ...el,
    items: subMenu.children,
  };
});

export const buildEntity = (element) => {
  if (element.type === null) {
    errorLogger(`Local type of ${element.title} is null`);
  }

  const output = {
    ...element,
    promoFromDate: GraphQLTransforms.timestamp(
      element.promoFromDate && element.promoFromDate.value,
    ),
    promoToDate: GraphQLTransforms.timestamp(element.promoToDate && element.promoToDate.value),
    pricePromo: parseFloat(element.pricePromo),
    priceM2Promo: parseFloat(element.priceM2Promo),
    price: parseFloat(element.price),
    priceM2: parseFloat(element.priceM2),
    priceNetto: parseFloat(element.priceNetto),
    promoLowestPrice: parseFloat(element.promoLowestPrice),
    status: element.status.entity || {},
    floor: { id: element.floor || 0, name: element.floor || 0 },
    localNumber: `${element.investment.entity.buildingNumber}/${element.localNumber}`,
    nr: element.localNumber,
    isLocalCommercial: element.isLocalCommercial,
    building: {
      id: element.investment.entity.id,
      title: element.investment.entity.buildingNumber,
      slug: element.investment.entity.slug,
    },
    investment: element.investment.entity.fieldBuildingInvestment.entity,
    type: get(element, 'type.entity', {}),
    bussinesPremiseType: element.bussinesPremiseType && element.bussinesPremiseType.entity,
    rooms: { id: element.rooms || 0, name: element.rooms || 0 },
    endDate: new Date(element.investment.entity.fieldBuildingFinishDate.value).getTime(),
    visualisationUrl: GraphQLTransforms.imageStyle(element.visualisationUrl),
  };

  if (!(output.promoType && output.promoFromDate < new Date().getTime() < output.promoToDate)) {
    delete output.priceM2Promo;
    delete output.pricePromo;
  }

  delete output.promoType;
  delete output.promoFromDate;
  delete output.promoToDate;

  if (element.full) {
    Object.assign(output, {
      surface: parseFloat(element.surface),
      imageUrl: GraphQLTransforms.image(element.imageUrl),
      roomsList: element.roomsList.map((el2) => ({
        ...el2.entity,
        name: GraphQLTransforms.value(el2.entity.name),
      })),
      direction: element.direction.map((el) => el.entity).filter((el) => !isNull(el)),
    });

    const features = {};

    forEach(output.roomsList, (room) => {
      const isFeature = includes(
        [
          config.search.featureIds.balcony,
          config.search.featureIds.balconyBuiltUp,
          config.search.featureIds.loggia,
          config.search.featureIds.loggiaClosed,
          config.search.featureIds.garden,
          config.search.featureIds.terrace,
          config.search.featureIds.terraceGreen,
        ],
        room.name,
      );

      if (isFeature) {
        features[room.name] = true;
      }
    });

    output.features = Object.keys(features).map((key) => ({ name: key }));
  }

  return output;
};

export const pushUniqueElement = (array, element) => {
  const newArray = [...array];

  if (!find(newArray, (el) => el.id === element.id)) {
    newArray.push(element);
  }

  return newArray;
};

export const mergeLocals = (teaserList, fullList) => {
  const output = {};

  teaserList.forEach((el) => {
    output[el.id] = el;
  });

  fullList.forEach((el) => {
    output[el.id] = el;
    output[el.id].full = true;
  });

  return Object.values(output);
};

export function convertUrlStringToArray(parameter) {
  if (isArray(parameter)) return parameter;

  if (isString(parameter)) {
    if (!isEmpty(parameter)) return [parameter];
  }

  return [];
}

const getLocalNumber = (item) => replace(item.localNumber, /^(\w+\/)/, '');
const localNumberComparator = (a, b) => {
  const numberA = Number(a);
  const numberB = Number(b);
  const aArrayWithoutComma = a.split(',')[0];
  const bArrayWithoutComma = b.split(',')[0];
  const aArray = aArrayWithoutComma.split('');
  const bArray = bArrayWithoutComma.split('');

  if (Number.isNaN(numberA) || Number.isNaN(numberB)) {
    const aLetters = [];
    let aNumbers = [];
    const bLetters = [];
    let bNumbers = [];

    aArray.forEach((letterFromA) => {
      if (Number.isNaN(Number(letterFromA)) && letterFromA !== '.') {
        aLetters.push(letterFromA);
      } else {
        aNumbers.push(letterFromA);
      }
    });

    bArray.forEach((letterFromB) => {
      if (Number.isNaN(Number(letterFromB)) && letterFromB !== '.') {
        bLetters.push(letterFromB);
      } else {
        bNumbers.push(letterFromB);
      }
    });

    const aLettersString = aLetters.join('');
    const bLettersString = bLetters.join('');

    if (aLettersString !== bLettersString) {
      return aLettersString.localeCompare(bLettersString);
    } if (Number(aNumbers.join('')) !== Number(bNumbers.join(''))) {
      if (aNumbers.length === bNumbers.length) {
        return Number(aNumbers.join('')) - Number(bNumbers.join(''));
      }

      aNumbers = aNumbers.includes('.') && aNumbers.length < bNumbers.length ? [...aNumbers.join('').split('.')[0], '.', '0', ...aNumbers.join('').split('.')[1]] : ['0', ...aNumbers];
      bNumbers = bNumbers.includes('.') && bNumbers.length < aNumbers.length ? [...bNumbers.join('').split('.')[0], '.', '0', ...bNumbers.join('').split('.')[1]] : ['0', ...bNumbers];

      return Number(aNumbers.join('')) - Number(bNumbers.join(''));
    }

    return a.localeCompare(b);
  }

  return numberA - numberB;
};
const getPromoPrice = (el) => (el.pricePromo ? el.pricePromo : el.price);

// Local
const sortLocalAsc = (a, b) => localNumberComparator(getLocalNumber(a), getLocalNumber(b));
const sortLocalDesc = (a, b) => localNumberComparator(getLocalNumber(b), getLocalNumber(a));
// Price
const sortPriceAsc = (a, b) => getPromoPrice(a) - getPromoPrice(b);
const sortPriceDesc = (a, b) => getPromoPrice(b) - getPromoPrice(a);
// Price netto
const sortPriceNettoAsc = (a, b) => a.priceNetto - b.priceNetto;
const sortPriceNettoDesc = (a, b) => b.priceNetto - a.priceNetto;
// Price m2
const sortPriceM2Asc = (a, b) => a.priceM2 - b.priceM2;
const sortPriceM2Desc = (a, b) => b.priceM2 - a.priceM2;
// Investment
const sortInvestmentAsc = (a, b) => a.investment.name.localeCompare(b.investment.name);
const sortInvestmentDesc = (a, b) => b.investment.name.localeCompare(a.investment.name);
// Status
const sortStatusAsc = (a, b) => a.status.name.localeCompare(b.status.name);
const sortStatusDesc = (a, b) => b.status.name.localeCompare(a.status.name);
// Price
const sortRoomsAsc = (a, b) => a.rooms.name - b.rooms.name;
const sortRoomsDesc = (a, b) => b.rooms.name - a.rooms.name;
// Features
const sortFeaturesAsc = (a, b) => a.features.length - b.features.length;
const sortFeaturesDesc = (a, b) => b.features.length - a.features.length;
// Floor
const sortFloorAsc = (a, b) => a.floor.name - b.floor.name;
const sortFloorDesc = (a, b) => b.floor.name - a.floor.name;
// Surface
const sortSurfaceAsc = (a, b) => a.surface - b.surface;
const sortSurfaceDesc = (a, b) => b.surface - a.surface;

const sortTypeAsc = (a, b) => a.type.localeCompare(b.type);
const sortTypeDesc = (a, b) => b.type.localeCompare(a.type);

export const getInvestmentsComparator = (selected) => {
  switch (selected) {
    case 'local-asc':
      return sortLocalAsc;
    case 'local-desc':
      return sortLocalDesc;
    case 'price-asc':
      return sortPriceAsc;
    case 'price-desc':
      return sortPriceDesc;
    case 'priceNetto-asc':
      return sortPriceNettoAsc;
    case 'priceNetto-desc':
      return sortPriceNettoDesc;
    case 'pm2-asc':
      return sortPriceM2Asc;
    case 'pm2-desc':
      return sortPriceM2Desc;
    case 'status-asc':
      return sortStatusAsc;
    case 'status-desc':
      return sortStatusDesc;
    case 'rooms-asc':
      return sortRoomsAsc;
    case 'rooms-desc':
      return sortRoomsDesc;
    case 'features-asc':
      return sortFeaturesAsc;
    case 'features-desc':
      return sortFeaturesDesc;
    case 'investment-asc':
      return sortInvestmentAsc;
    case 'investment-desc':
      return sortInvestmentDesc;
    case 'floor-asc':
      return sortFloorAsc;
    case 'floor-desc':
      return sortFloorDesc;
    case 'surface-asc':
      return sortSurfaceAsc;
    case 'surface-desc':
      return sortSurfaceDesc;
    case 'type-asc':
      return sortTypeAsc;
    case 'type-desc':
      return sortTypeDesc;
    default:
      return sortLocalAsc;
  }
};
