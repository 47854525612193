import styled from 'styled-components';

const MenuWrapper = styled.div`
  display: inline-flex;
  background-color: ${(props) => props.theme.primary2Color};
  box-shadow: 0 65px 65px -22px rgba(${(props) => props.theme.shadowColor});
  margin-left: 32px;
  margin-top: -1px;
  border-top: 3px solid ${(props) => props.theme.secondary8Color};
`;

const Wrapper = styled.div`
  width: 300px;
  height: auto;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const EntryContainer = styled.div`
  display: block;
`;

export { MenuWrapper, Wrapper, EntryContainer };
