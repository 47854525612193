import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.primary2Color};
  color: ${(props) => props.theme.primary3Color};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 32px;
  line-height: 14px;
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  cursor: default;
  position: relative;
  height: 100%;
  font-size: 15px;
  z-index: -1;

  ${({ active }) => active && css`
    cursor: pointer;
  `}
`;

const HoverUnderline = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: ${(props) => props.theme.secondary8Color};
  width: 68px;
  max-width: 100%;
  height: 5px;
`;

export {
  Wrapper,
  HoverUnderline,
};
