import styled from 'styled-components';

const SearchButton = styled.button`
  display: flex;
  gap: 16px;
  padding: 16px 32px 16px 24px;
  border: none;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.primary4Color};
  fill: ${(props) => props.theme.secondary3Color};
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.2px;
  font-weight: 700;
  color: ${(props) => props.theme.white};

  &:hover {
    background-color: ${(props) => props.theme.secondary17Color};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 40px 0 30px auto;
    height: 30px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;

  svg {
    transform: scale(1.15);
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: 18px;
    width: 18px;
  }
`;

export {
  SearchButton, Icon,
};
