import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import FullArrow from 'components/icons/FullArrow';
import Link from 'components/ui/Link';

import { getImgDimensions } from 'utils/getImgDimensions';
import { PathPartShape } from './Breadcrumbs.shapes';

import {
  Delimiter,
  PathPart,
  PathWrapper,
  Content,
  ImageWrapper,
  Image,
  BreadcrumbsWrapper,
} from './Breadcrumbs.styles';
import { VisibilityContainer } from '../../utils/styles';

const Breadcrumbs = ({
  imageUrl,
  pathParts,
  theme,
  onImageLoad,
}) => {
  const [imageDimensions, setImageDimensions] = useState('');

  const imgRef = useRef(null);

  const onLoad = () => {
    getImgDimensions(imageUrl)
      .then((dimensions) => setImageDimensions(`${dimensions.width} / ${dimensions.height}`));
  };

  useEffect(() => {
    onImageLoad();

    if (imgRef.current) {
      onLoad();
    }
  }, [imageUrl]);

  return (
    <BreadcrumbsWrapper>
      <ImageWrapper imageDimensions={imageDimensions}>
        <Image ref={imgRef} src={imageUrl} />
      </ImageWrapper>
      <VisibilityContainer md lg>
        <Content id="scroll">
          <PathWrapper>
            {
              pathParts.map((el, key) => (
                <React.Fragment key={el.id}>
                  <Link route={el.route} params={el.params}><PathPart>{el.name}</PathPart></Link>
                  {
                    key === pathParts.length - 1 ? null : (
                      <Delimiter>
                        <FullArrow fill={theme.primary1Color} />
                      </Delimiter>
                    )
                  }
                </React.Fragment>
              ))
            }
          </PathWrapper>
        </Content>
      </VisibilityContainer>
    </BreadcrumbsWrapper>
  );
};

Breadcrumbs.propTypes = {
  theme: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  imageUrl: PropTypes.string,
  pathParts: PropTypes.arrayOf(PathPartShape),
  onImageLoad: PropTypes.func,
};

Breadcrumbs.defaultProps = {
  imageUrl: null,
  pathParts: [],
  onImageLoad: () => {},
};

export default withTheme(Breadcrumbs);
