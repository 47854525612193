import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Magnifier from 'components/icons/Magnifier';
import Mail from 'components/icons/Mail';
import Phone from 'components/icons/PhoneEmpty';
import { Router } from 'Router';
import ContactIcon from './components/ContactIcon';

import { Wrapper, ElementWrapper } from './ContactIcons.styles';
import messages from './ContactIcons.messages';


const ContactIcons = ({ theme, mail, phone }) => {
  const onContactClick = () => {
    Router.pushRoute('contactForm');
  };

  return (
    <Wrapper>
      <ElementWrapper>
        <ContactIcon
          href="/wyszukiwarka"
          theme={theme}
          icon={<Magnifier />}
          description={<FormattedMessage {...messages.search} />}
        />
      </ElementWrapper>
      {mail ? (
        <ElementWrapper>
          <ContactIcon
            onClick={onContactClick}
            icon={<Mail />}
            description={<FormattedMessage {...messages.mail} />}
          />
        </ElementWrapper>
      ) : null}
      {phone ? (
        <ElementWrapper>
          <ContactIcon
            href={`tel:${phone}`}
            icon={<Phone width={31} height={31} />}
            description={<FormattedMessage {...messages.phone} values={{ phone }} />}
          />
        </ElementWrapper>
      ) : null}
    </Wrapper>
  );
};


ContactIcons.propTypes = {
  mail: PropTypes.string,
  phone: PropTypes.string,
};

ContactIcons.defaultProps = {
  mail: null,
  phone: null,
};

export default ContactIcons;
