import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'nuka-carousel';
import _map from 'lodash/map';

import config from 'config';

import { VisibilityContainer } from 'utils/styles';
import Slide from './components/Slide';
import { SliderItemShape } from './Slider.shapes';
import {
  SliderWrapper,
} from './Slider.styles';
import Arrow from './components/Arrow';

const Slider = ({
  items, variant, showModal, onSliderLoaded,
}) => {
  const isLocalVariant = variant === 'local';

  useEffect(() => {
    onSliderLoaded();
  }, [items, variant, showModal]);

  return (
    <SliderWrapper isLocalVariant={isLocalVariant}>
      <Carousel
        autoplay
        autoplayInterval={config.slider.autoplayInterval}
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <VisibilityContainer lg>
            <Arrow onClick={previousSlide} direction="left" />
          </VisibilityContainer>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <VisibilityContainer lg>
            <Arrow onClick={nextSlide} direction="right" />
          </VisibilityContainer>
        )}
        renderBottomCenterControls={null}
        swiping
        pauseOnHover={false}
      >
        {
          _map(items, (el, key) => (
            <Slide
              key={key}
              showModal={showModal}
              {...el}
            />
          ))
        }
      </Carousel>
    </SliderWrapper>
  );
};

Slider.propTypes = {
  items: PropTypes.arrayOf(SliderItemShape),
  showModal: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'local']),
  onSliderLoaded: PropTypes.func,
};

Slider.defaultProps = {
  items: [],
  variant: 'default',
  showModal: () => {},
  onSliderLoaded: () => {},
};

export default Slider;
