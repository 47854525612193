import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin-left: 5px;

  > svg {
    position: absolute;
    width: auto;
    height: 100%;
  }
`;

export { Wrapper };
